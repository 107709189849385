
import { Component, Vue } from 'vue-property-decorator';
import PQuestaoCard from '@/components/layouts/p-QuestaoCard.vue';
import { VsOption } from '@/components/layouts/vs-alert';
import { VsTimerInstance } from '@/components/controls/vs-timer';
import VsLoadingModal from '@/components/layouts/vs-LoadingModal.vue';
import sentenceCase from '@/utils/sentenceCase';

import Tema from '@/models/Tema';
import Questao from '@/models/Questao';

@Component({
    components: { PQuestaoCard, VsLoadingModal },
    filters: { sentenceCase }
})
class QuestoesTeoricoPage extends Vue {

    // ========== Propriedades reativas ==============
    private carregandoPergunta: boolean = false;
    private tempo: number = this.$store.getters['GetDuracaoSimulado'];
    private indexQuestaoAtual: number = 0;
    private transition: string = 'toRight';


    // =========== Propriedades computadas ==========
    private get questoes() {
        return this.$store.getters['GetQuestoes'] as Questao[];
    }

    private get questaoAtual() {
        return this.questoes[this.indexQuestaoAtual];
    }

    private get temaQuestaoAtual(){
        return (this.$store.getters['GetTemas'] as Tema[]).find(el => el.id == this.questaoAtual.temaId)!.descricao;
    }
    private get loading(){
        return this.$store.getters['GetLoadingSimulado'];
    }

    // ============= m�todos ===================
    private navigate(index: number) {
        const nextIndex = this.indexQuestaoAtual + index;
        if(nextIndex >= 0 && nextIndex <= this.questoes.length - 1) {
            this.transition = nextIndex > this.indexQuestaoAtual ? 'nextQuestion' : 'prevQuestion';
            this.carregandoPergunta = true;
            setTimeout(() => {
                this.indexQuestaoAtual = nextIndex;
                this.carregandoPergunta = false;
            }, 600);
        }
    }

    private navigateToQuestion(index: number) {
        this.transition = index > this.indexQuestaoAtual ? 'nextQuestion' : 'prevQuestion';
        this.carregandoPergunta = true;
        setTimeout(() => {
            this.indexQuestaoAtual = index;
            this.carregandoPergunta = false;
        }, 600);
    }

    private getColorBar(index: number, questao: Questao){
        if(index == this.indexQuestaoAtual && !this.carregandoPergunta) {
            return 'var(--gray-1)';
        }
        else if(this.$route.name == 'conferencia-teorico') {
            const idAlternativaCorreta = questao.alternativas.find(a => a.alternativaCorreta)!.id;
            return questao.alternativaSelecionada == idAlternativaCorreta ? '#31BE9E' : '#E65252';
        }
        else if(questao.alternativaSelecionada > 0) {
            return 'var(--primary-color)';
        }
        else {
            return 'var(--border-1)';
        }
    }

    private timerFinished(){
        const options: VsOption[] = [
            {
                title: 'Tentar novamente',
                action: () => this.$store.commit('replace', { page: 'categoria-teorico', transition: 'crossFade' })
            },
            {
                title: 'Salvar',
                primary: true,
                action: this.finalizar
            }
        ];

        this.$pilotarAlert('Simulado concluido', 'Deseja tentar novamente ou salvar o resultado para revisar?', options);
    }

    private sair(){
        if(this.$route.name == 'conferencia-teorico') {
            this.$store.commit('goBack');
        }
        else {
            const options: VsOption[] = [
                {
                    title: 'Sair',
                    action: () => this.$store.commit('goBack')
                },
                {
                    title: 'Cancelar',
                    primary: true
                }
            ];
            this.$pilotarAlert('Deseja realmente sair?', 'Ao confirmar voc� perder� a pontua��o deste simulado.', options);
        }
    }

    private nextQuestion(){
        if(this.indexQuestaoAtual < this.questoes.length - 1) {
            this.navigate(1);
        }
        else if(this.questoes.filter(el => el.alternativaSelecionada == 0).length > 0 && this.$route.name == 'questoes-teorico') {
            this.$pilotarAlert('Voc� n�o respondeu todas as quest�es', 'Volte e visualize-as novamente');
        }
        else if(this.$route.name == 'questoes-teorico') {
            const options: VsOption[] = [
                {
                    title: 'Revisar'
                },
                {
                    title: 'Salvar',
                    action: this.finalizar,
                    primary: true
                }
            ];
            this.$pilotarAlert('Simulado conclu�do!', 'Deseja salvar as respostas e ver o resultado ou voltar para revisar?', options, 'success');
        }
    }

    private async finalizar(){
        try{
            // Setar tempo de resposta
            this.$store.commit('SetTempoFinalSimulado', this.tempo);
            (this.$refs.timer as VsTimerInstance).stop();

            // Caso seja usu�rio cadastrado, guardar as estat�sticas
            if(this.$store.getters['userRegistered']) {
                await this.$store.dispatch('ResponderSimulado');
            }
            // Navegar
            this.$store.commit('replace', { page: 'resultado-teorico', transition: 'crossFade' });
        }catch(err){
            console.log(err);
        }
    }
}

export default QuestoesTeoricoPage;
