
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class SimuladoTeoricoHome extends Vue {
    private iniciarSimulado() {
       
        if(this.$store.getters['getUserType'] == 'naoAluno' || this.$store.getters['getUserType'] == 'alunoNaoRegistrado') {
            this.$pilotarAlert(
                "Aten��o!",
                "Para que voc� consiga acompanhar o seu desempenho nos simulados, realize o seu cadastro antes de iniciar a partida.",
                [
                    {
                        title: "Jogar",
                        action: () => this.$store.commit('replace', { page: 'categoria-teorico' })
                    },
                    {
                        title: "Cadastrar-se",
                        action: () => this.$store.commit('navigate', { page: 'containicio', transition: 'crossFade' }),
                        primary: true
                    }
                ],
                'alert',
                { displayCloseButton: true }
            );
        }
        else {
            this.$store.commit('replace', { page: 'categoria-teorico' });
        }
    }
}

export default SimuladoTeoricoHome;
