import { render, staticRenderFns } from "./SimuladoTeoricoMainPage.vue?vue&type=template&id=5fcd0901"
import script from "./SimuladoTeoricoMainPage.vue?vue&type=script&lang=ts"
export * from "./SimuladoTeoricoMainPage.vue?vue&type=script&lang=ts"
import style0 from "./SimuladoTeoricoMainPage.vue?vue&type=style&index=0&id=5fcd0901&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports