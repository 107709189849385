
import { Component, Vue } from 'vue-property-decorator';
import PCategoriaCard from '@/components/layouts/p-CategoriaCard.vue';
import Tema from '@/models/Tema';
interface Categoria {
    id: number | null;
    questoes: number | null;
    duracao: number | null;
}

@Component({ components: { PCategoriaCard } })
class CategoriaSimulado  extends Vue {
    
    private mounted() {
        this.$store.dispatch('GetTemasApi');
    }

    // Vari�veis computadas
    public get loading() {
        return this.$store.getters['GetLoadingSimulado'];
    }

    public get listTemas(): Tema[] {
        return this.$store.getters['GetTemas'];
    }

    // Vari�veis reativas
    private temaSelecionado: Categoria = {
        id: null,
        questoes: null,
        duracao: null
    };

    private finalizarSelecao() {
        this.$store.commit('SetDadosTema', this.temaSelecionado);
        this.$emit('selecaoFinalizada');
    }
}

export default CategoriaSimulado;
