
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class SimuladoTeoricoMainPage extends Vue {
    
    private iniciarSimuladoModal: boolean = false;

    private get contentTop() {
        const size = this.$route.name == 'simulado-teorico' ? 260 : 55;
        return size + 'px';
    }

    private carregarSimulado(){
        this.$store.dispatch('GetQuestoesApi')
            .then(() => {
                this.$store.commit('navigate', { page: 'questoes-teorico', transition: 'crossFade' });
            });
    }

    private get loading() {
        return this.$store.getters['GetLoadingSimulado'];
    }
}

export default SimuladoTeoricoMainPage;
