
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    filters: {
        iconColor(selectedId: number | null, id: number) {
            if(selectedId == null)
                return 'var(--gray-1)';
            else if(selectedId == id) {
                return 'white';
            }
            else {
                return '#9C9C9D';
            }
        },
        icon(value: { id: number | null; questoes: number | null; duracao: number | null }, id: number) {
            if(value.id != id) {
                
                const categorias = ["categorias-sinalizacao", "categorias-direcao-defensiva", "categorias-mecanica", "categorias-ambiente", "categorias-socorros", "categorias-infracoes", "categorias-conduta"];
                return id == 99 ? 'categorias-todas' : categorias[id - 1];
            }
            else if(value.questoes == null) {
                return 'arrow-right-sm';
            }
            else {
                return 'check-sm';
            }
        }
    }
})
class PCategoriaCard extends Vue {

    @Prop({ type: Object, required: true })
    private value!: { id: number | null; questoes: number | null; duracao: number | null };

    @Prop({ type: Object, required: true })
    private categoria!: { id: number; descricao: string};

    private questoesTemp: number = 5;
    private duracaoTemp: number = 10;

    private select() {
        if(this.value.id != this.categoria.id) {
            this.questoesTemp = 5;
            this.duracaoTemp = 10;


            const el = this.$refs.button as HTMLElement;
            el.style.animation = '';
            el.clientHeight;
            el.style.animation = 'rotateButton .6s';

            this.$emit('input', {
                id: this.categoria.id,
                questoes: null,
                duracao: null
            });
        }
    }
    private get selected(): boolean {
        return this.value.id == this.categoria.id;
    }

    private selectButton() {
        if(this.selected) {
            const value = {
                id: this.value.id,
                questoes: this.value.questoes == null ? this.questoesTemp : this.value.questoes, 
                duracao: this.value.questoes != null ? this.duracaoTemp: null
            };
            
            this.$emit('input', value);
            
            if(value.duracao != null) {
                this.$emit('finished');
            }
            else {
                const el = this.$refs.button as HTMLElement;
                el.style.animation = '';
                el.clientHeight;
                el.style.animation = 'rotateButton .6s';
            }
        }
    }
}

export default PCategoriaCard;
