
import { Component, Vue } from 'vue-property-decorator';
import { VsOption } from '@/components/layouts/vs-alert';

@Component({ })
class ResultadoTeoricoPage extends Vue {
    private get questoes(): number{
        return this.$store.getters['GetQuestoes'].length;
    }

    private get questoesCorretas(): number {
        return this.$store.getters['GetQuantidadeQuestoesCorretas'];
    }

    private get tempoSimulado(): string {
        return this.$store.getters['GetTempoSimulado'];
    }

    private get porcentagem(): number {
        return Math.round((this.questoesCorretas/ this.questoes) * 100);
    }

    private get visualizouRespostas(): boolean {
        return this.$store.getters['GetVisualizouRespostas'];
    }

    private verRespostas(){
        this.$store.commit('SetVisualizouRespostas', true);
        this.$store.commit('navigate', { page: 'conferencia-teorico', transition: 'toLeft' });
    }

    private sair(){
        if(this.visualizouRespostas) {
            this.$store.commit('SetVisualizouRespostas', false);
            this.$store.commit('clearStack', { page: 'simulados', transition: 'crossFade' });
        }   
        else {
            const options: VsOption[] = [
                {
                    title: 'Sair',
                    action: () => {
                        this.$store.commit('clearStack', { page: 'simulados', transition: 'crossFade' });
                    }
                },
                {
                    title: 'Cancelar',
                    primary: true
                }
            ];
            this.$pilotarAlert('Deseja sair sem revisar as suas respostas?', 'Ap�s confirmar n�o ser� mais poss�vel vizualiz�-las.', options, 'simuladoteorico/sair-simulado');
        }
    }
}

export default ResultadoTeoricoPage;
